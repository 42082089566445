<!-- 客户管理 -- 往届生续费 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        has-add
        @onAdd="$router.push('./add')"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
    <el-dialog title="编辑收费科目" @close="onDialogClose" :visible.sync="visible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="120rem">
        <el-form-item label="科目名称" prop="name" :rules="{required: true,message: '请输入科目名称',trigger: 'blur'}">
          <el-input v-model="form.name" placeholder="请输入" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <span>{{form.type}}</span>
        </el-form-item>
        <el-form-item label="金额">
          <span>{{form.price}}</span>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button @click="visible = false" :disabled="dialogLoading">取消</el-button>
        <el-button type="primary" @click="submit" :loading="dialogLoading">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue";

export default {
  _config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      visible:false,
      form:{
        name:null,
        price:null,
        type:null
      },
      search: {},
      dialogLoading:false,
      searchConfig: [
        {
          prop: "name",
          placeholder: "搜索科目名称",
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      tableConfig: [
        {prop: "name", label: "科目名称"},
        {prop: "type", label: "类型"},
        {prop: "price", label: "金额"},
        {prop: "creator", label: "创建人"},
        {prop: "created_at", label: "创建时间"},
        {
          prop: "handle",
          label: "操作",
          width: "120px",
          handle: true,
          render(row) {
            return ['编辑']
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData() {
      this.loading = true;
      let params = {page:this.page}
      params = Object.assign(params,this.search||{});
      this.$_register.get("/api/finance/fee/list",{
        params
      }).then(res=>{
        this.tableData = res.data.data.list;
        this.total = res.data.data.page.total;
      }).finally(()=>this.loading = false)
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData()
    },

    handleEdit(row, text) {
      let loading = this.$loading({
        text:"请稍后..."
      })
      this.$_register.get("api/finance/fee/show",{
        params:{
          id:row.id
        }
      }).then(res=>{
        this.form = res.data.data;
        this.$nextTick(()=>{
          this.visible = true;
        })

      }).finally(()=>loading.close())

    },
    onDialogClose(){
      this.form = {
        name:null,
        price:null,
        type:null
      }
    },
    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },
    submit(){
      this.$refs.form.validate((res)=>{
        if (!res)return;
        this.dialogLoading = true;
        this.$_register.post("api/finance/fee/update",this.form).then(res=>{
          this.visible = false;
          this.$nextTick(()=>{
            this.dialogLoading = false;
          })
          this.getData();
        }).catch(()=>this.dialogLoading = false)
      })

    }

  },
}
</script>

<style lang="scss" scoped>
</style>
